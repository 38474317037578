import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateSessionComponent } from './create-session/create-session.component';
import { SessionListComponent } from './session-list/session-list.component';
import { IndividualSessionComponent } from './individual-session/individual-session.component';
import { UserSessionListComponent } from './user-session-list/user-session-list.component';
import { SigninGuardGuard } from 'src/app/signin-guard.guard';
import { LearnLoginComponent } from './learn-login/learn-login.component';
import { RtmAddParticipantComponent } from './rtm-add-participant/rtm-add-participant.component';

const routes: Routes = [
  { path: 'create_session/:typeId/:sid',  component: CreateSessionComponent},
  { path: 'session_list',  component: SessionListComponent},
  { path: 'user_session_list',  component: UserSessionListComponent},
  { path: 'view_session/:sid',  component: IndividualSessionComponent},
  { path: 'learn-login/:user_data/:token', component: LearnLoginComponent, canActivate: [SigninGuardGuard]},
  { path: 'rtm_add_participant/:id',  component: RtmAddParticipantComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InfogeonClassroomRoutingModule { }
