import { environment } from 'src/environments/environment'
// import { environment } from 'src/environments/environment.prod'



// APPS
 export const baseUrl = environment.production ? 'https://api.bsharpcorp.com' : 'http://localhost:8000'

// QA
// export const baseUrl = environment.production ? 'http://api.bsharp.in' : 'http://localhost:8000'

export const local_base_url = location.origin;

// APPS
 export const link_base_Url = environment.production ? 'https://api.bsharpcorp.com' : 'http://localhost/infogeon'

// QA
// export const link_base_Url = environment.production ? 'http://api.bsharp.in' : 'http://localhost/infogeon'

// export const filed_xchange_server = 'local'
// export const filed_xchange_server = 'qa'
export const filed_xchange_server = 'prod'



export const myTaskList = baseUrl + '/task/list'
export const myTaskUpdate = baseUrl + '/task/update'

// export const AWSkeySet = {
//     AWS_UPLOAD_ACCESS_KEY_ID: '',
//     AWS_UPLOAD_SECRET_KEY: '',
//     AWS_UPLOAD_REGION: 'ap-southeast-1',
//     AWS_UPLOAD_BUCKET: 'bsharp-django-files',
//     AWS_ACL: 'private',
// }


// export const navbar_const = 'https://qa-infogeon.bsharp.in'

// export const AWSkeySet = {
//    AWS_UPLOAD_ACCESS_KEY_ID: '',
//    AWS_UPLOAD_SECRET_KEY: '',
//    AWS_UPLOAD_REGION: 'ap-southeast-1',
//    AWS_UPLOAD_BUCKET: 'bsharp-trainings',
//    AWS_ACL: 'private',
// }
//  export const s3_file_path = 'qa-infogeon.bsharp.in'


//Production Config

export const navbar_const = 'https://apps.bsharpcorp.com'

export const AWSkeySet = {
    // AWS_UPLOAD_ACCESS_KEY_ID: '',
    AWS_UPLOAD_ACCESS_KEY_ID: '',
    ///AWS_UPLOAD_ACCESS_KEY_ID: '',
    // AWS_UPLOAD_SECRET_KEY: '',
    AWS_UPLOAD_SECRET_KEY: '',
    //AWS_UPLOAD_SECRET_KEY: '',
    AWS_UPLOAD_REGION: 'ap-southeast-1',
    AWS_UPLOAD_BUCKET: 'bsharptest',
    AWS_ACL: 'private',
}
export const s3_file_path = 'apps.bsharpcorp.com'

