import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IGX_INPUT_GROUP_TYPE } from 'igniteui-angular';
import { InfogeonClassroomService } from '../infogeon-classroom.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ExcelServicesService } from 'src/app/services/excel-services.service';

import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import * as alertify from 'alertifyjs';
import { GlobalConstants } from 'src/app/global-const';


@Component({
  selector: 'app-session-list',
  templateUrl: './session-list.component.html',
  styleUrls: ['./session-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: IGX_INPUT_GROUP_TYPE, useValue: 'box' }]
})
export class SessionListComponent implements OnInit {
  formGroup: FormGroup;
  moduleList: any[] = [];
  moduleNamesList: any[] = [];
  tableData: any[] = [];
  orgGroupSelectedItems = []
  moduleDropdownSettings = {
    singleSelection: false,
    text: "Select the topic",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    showCheckbox: true,
    labelKey: 'itemName',
    maxHeight: 120,
    badgeShowLimit: 1,
    searchBy: ["itemName"],
    classes: "modules_drop primaryText filter_drop alignSelfCenter"
  };
  trainerDropdownSettings = {
    singleSelection: false,
    text: "Select Trainer",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    showCheckbox: true,
    searchBy: ["itemName"],
    badgeShowLimit: 1,
    classes: "modules_drop primaryText filter_drop alignSelfCenter"
  };
  screenWidth: number;
  isMobile: boolean = false;
  isDtForApply: boolean = true;
  isFilterApplied: boolean = false;
  itemsPerPage: number = 10;
  currentPage: number = 1;
  totalCount: number = 0;
  model: NgbDateStruct;
  loggedInTimeZone: any = '';
  fullSessionList: any;
  lastMonthSessionList: any;

  filterObj = {}
  from_date: NgbDateStruct;
  to_date: NgbDateStruct;
  admin: any;
  rtmTrainerList: any;
  minDateStr: string;
  maxDateStr: string;
  trainer_name:any 
  currentTimeEpoch:any
  // isDropdownOpen: number | null = null;
  openDropdown: { parentIndex: number | null; rowIndex: number | null } = {
    parentIndex: null,
    rowIndex: null,
  };
  constructor(
    private formBuilder: FormBuilder,
    private classroomService: InfogeonClassroomService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private excelService: ExcelServicesService,
    private titleService: Title,
    private datePipe: DatePipe,
    private router: Router,
  ) {
    this.titleService.setTitle("Classroom");
    this.filterObj = {
      'page_number': this.currentPage,
      'item_per_page': this.itemsPerPage,
      'filter_applied': this.isFilterApplied,
      'session_date': '',
      'from_date': '',
      'to_date': '',
      'mid': '',
      'classroom_type': '',
      'status': '',
      'is_lc_session': 0,
      'trainer': ''
    }
    this.currentTimeEpoch = Math.floor(new Date().getTime() / 1000);
    // console.log(this.currentTimeEpoch);
    // this.fetchFullSessionList();
  }
 
  ngOnInit(): void {
    const currentDate = new Date();
    this.loggedInTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.formGroup = this.formBuilder.group({
      module: [],
      type: [],
      status: [],
      from_date: [],
      to_date: [],
      trainer: [],
    });
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 992) { // for Desktop apply btn(filter)
      this.isDtForApply = false
    }
    if (this.screenWidth < 576) { // for mobile
      this.isMobile = true
    }

    // this.filterObj = {
    //   'page_number': this.currentPage,
    //   'item_per_page': this.itemsPerPage,
    //   'filter_applied': this.isFilterApplied,
    //   'session_date': '',
    //   'from_date': '',
    //   'to_date': '',
    //   'mid': '',
    //   'classroom_type': '',
    //   'status': '',
    //   'is_lc_session': 0,
    //   'trainer': ''
    // }

    this.fetchSessionList();
    // this.lastThreeMonthSessionList();
    // this.fetchSessionNameList()
    // this.fetchModuleList()
    this.AllrtmTopicList();
    this.fetchRtmTrainers();
    
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 576) { // for mobile
      this.isMobile = true
    } else {
      this.isMobile = false
    }

    if (this.screenWidth > 991) { // for Desktop apply btn(filter)
      this.isDtForApply = true
    } else {
      this.isDtForApply = false
    }
  }

  filterSession() {
  
      this.isFilterApplied = true

        if (this.formGroup.value.date) {
          let date = this.formGroup.value.date
          const date_sess_set = new Date(date.year, date.month - 1, date.day);
          this.filterObj['session_date'] = Math.floor(date_sess_set.getTime() / 1000);
        } else {
          this.filterObj['session_date'] = ''
        }
        if(this.formGroup.value.from_date){
          let date = this.formGroup.value.from_date
          const date_sess_set = new Date(date.year, date.month - 1, date.day);
          this.filterObj['from_date'] = Math.floor(date_sess_set.getTime() / 1000);
        }else{
          this.filterObj['from_date'] = ''
        }

        if(this.formGroup.value.to_date){
          let date = this.formGroup.value.to_date
          const date_sess_set = new Date(date.year, date.month - 1, date.day);
          this.filterObj['to_date'] = Math.floor(date_sess_set.getTime() / 1000);
        }else{
          this.filterObj['to_date'] = ''
        }

        if (this.formGroup.value.module) {
          this.filterObj['mid'] = this.formGroup.value.module
        }

        if (this.formGroup.value.type) {
          this.filterObj['classroom_type'] = this.formGroup.value.type
        }
        if (this.formGroup.value.status) {
          this.filterObj['status'] = this.formGroup.value.status
        }

        if(this.formGroup.value.trainer){
          this.filterObj['trainer'] = this.formGroup.value.trainer
        }
        this.filterObj['page_number'] = 1
        this.filterObj['filter_applied'] = this.isFilterApplied
        // console.log(this.filterObj)
        // this.lastThreeMonthSessionList();
        this.fetchSessionList();
        // this.fetchFullSessionList();
       

  }

  fetchRtmTrainers(){
    this.classroomService.get_rtm_trainers_list().subscribe(res => {
      this.rtmTrainerList = res;
    })
  }

  // fetchSessionNameList() {
  //   this.classroomService.session_name_list().subscribe(res => {
  //     // console.log(res)
  //     this.moduleList = res;
  //     //console.log(this.modDropdownList);
  //   })
  // }


  // fetchModuleList() {
  //   this.classroomService.module_list().subscribe(res => {
  //     this.moduleNamesList = res;
  //     // console.log(this.moduleList);
  //   })
  // }
  AllrtmTopicList() {
    this.classroomService.rtm_topic_list().subscribe(rtmTopicList => {
      this.moduleNamesList = rtmTopicList;
    })
  }

  fetchSessionList() {

    this.spinner.show();
    this.classroomService.trainers_session_list(this.filterObj).toPromise().then(res => {
      // console.log(res)
      this.tableData = res.data;
      this.totalCount = res.total_count;
      this.admin = res.admin;
      this.trainer_name = res.trainer;
      // console.log(this.tableData)
      this.spinner.hide();
      this.cdr.detectChanges();
      
      // this.spinner.hide();
    })
  }

  pageChange(event) {
    this.currentPage = event;
    this.filterObj['page_number'] = this.currentPage
    // this.getAssignedToMeTask(this.dateapipe);
    this.fetchSessionList()

  }


  resetBtnClick() {
    this.isFilterApplied = false


    this.formGroup = this.formBuilder.group({
      module: [],
      type: [],
      status: [],
      date: [],
      from_date: [],
      to_date: [],
      trainer: [],
    });


    this.filterObj = {
      'page_number': this.currentPage,
      'item_per_page': this.itemsPerPage,
      'filter_applied': this.isFilterApplied,
      'session_date': '',
      'from_date': '',
      'to_date': '',
      'mid': '',
      'classroom_type': '',
      'status': '',
      'is_lc_session' : 0,
      'trainer': ''
    }
    // this.lastThreeMonthSessionList();
    this.fetchSessionList();
    // this.fetchFullSessionList();
    

  }



  excel: any = [{
    session_id: '',
    session_name: '',
    session_date: '',
    from_time: '',
    to_time: '',
    timezone: '',
    classroom_type: '',
    session_type: '',
    meeting_link: '',
    session_key: '',
    module_name: '',
    category: '',
    sub_category: '',
    // group_name: '',
    tot_user_count: '',
    // entrolled_count: '',
    attendance_count: '',
    session_status: '',
    trainer_channel: '',
    trainer_region: '',
    cancel_reason: '',
  }]
  excelUser: any = [{
    session_id: '',
    first_name: '',
    last_name: '',
    mobile_no: '',
    email_id: '',
    module_name: '',
    session_name: '',
    type: '',
    trainer_name: '',
    trainer_type: '',
    category: '',
    sub_category: '',
    from_time: '',
    to_time:'',
    duration: '',
    publishedDate: '',
    sessionDate: '',
    dateOfCompletion: '',
    completionStatus: '',
    pre_score:'',
    post_score: '',
    userType: '',
    sessionStatus:'',
    RegistrationMethod: '',
    cancel_reason: ''
    // attendance: '',
    
  }]

  //consolidate report
  all_user:any = [{
    session_id: '',
    first_name: '',
    last_name: '',
    mobile_no: '',
    email_id: '',
    module_name: '',
    category: '',
    sub_category: '',
    session_name: '',
    classroom_type: '',
    // session_type: '',
    attendance_status: '',
    session_key: '',
    trainer_type: '',
    trainer_name: '',
    trainer_email_id: '',
    trainer_mobile_no: '',
    session_status: '', 
    from_time: '',
    to_time:'',
    duration: '',
    publishedDate: '',
    sessionDate: '',
    dateOfCompletion: '',
    pre_score: '',
    post_score: '',
    user_type: '',
    user_status: '',
    registration_method: '',
    cancel_reason: '',
  }]


  sess_type: any;
  class_type: any;
  trainer_type:any;
  
  downloadSessionData() {
    this.excel= [];
    this.fullSessionList = [];
    this.spinner.show();
    let allSessionPromise = new Promise((resolve, reject) => {
      this.classroomService.trainers_session_full_list(this.filterObj).subscribe(res => {
        this.fullSessionList = res;
        var that = this;
        if(this.fullSessionList.length!=0){
          console.log("dff", this.fullSessionList)
          this.fullSessionList.forEach(function (item: any) {
            if (item.classroom_type == 0) {
              that.class_type = 'Online'
            } else if (item.classroom_type == 1) {
              that.class_type = 'Offline'
            } else {
              that.class_type = 'Online & Offline'
            }
      
            var session_status = '';
            if(item.status == 0){
              session_status = 'Open';
            }else if(item.status == 1 || item.status == 5){
              session_status = 'Completed';
            }else if(item.status == 2){
              session_status = 'Cancelled';
            }else if(item.status == 3){
              session_status = 'In progress';
            }else if(item.status == 4){
              session_status = 'Expired';
            }
      
            if(item.trainer_type == 1){
              that.trainer_type = 'Facilitator';
            }else if(item.trainer_type == 2){
              that.trainer_type = 'Lead Trainer';
            }
      
            let sessionData: any = {
              session_id: item.session_id,
              session_name: item.session_name,
              module_name: item.module_name,
              category: item.category,
              sub_category: item.sub_category,
              session_date: item.session_date,
              from_time: item.from_time,
              to_time: item.to_time,
              duration: item.duration,
              timezone: item.timezone,
              classroom_type: that.class_type,
              // session_type: that.sess_type,
              meeting_link: item.meeting_link,
              session_key: item.session_key,       
              // group_name: item.group_name,
              tot_user_count: item.tot_user_count,
              // entrolled_count: item.entrolled_count,
              attendance_count: item.attendance_count,
              trainer_type: that.trainer_type,
              trainer_name: item.trainer_name,
            };
            if(GlobalConstants.userDetails.cmid == 1 || GlobalConstants.userDetails.cmid == 2935 || GlobalConstants.userDetails.cmid== 2963 ||  GlobalConstants.userDetails.cmid == 2965 ||  GlobalConstants.userDetails.cmid == 2962 || GlobalConstants.userDetails.cmid == 2964 || GlobalConstants.userDetails.cmid == 2974){
              sessionData.trainer_channel = item.trainer_channel;
              sessionData.trainer_region = item.trainer_region;
              sessionData.session_status = session_status; 
              sessionData.cancel_reason = item.cancel_reason;
            }else{
              sessionData.session_status = session_status;
              sessionData.cancel_reason = item.cancel_reason;
            }
            // Push the completed sessionData object into the excel array
            that.excel.push(sessionData);
           
          });
          this.excelService.exportAsExcelFile(this.excel, 'session-list');
          this.spinner.hide(); 
        }
            
      })

    });
  }

  activeModule = 'module'; // Initial active module
  activeModules: { [key: string]: string } = {}; 
  isActiveModule(moduleType: string, sid: string): boolean {
    return this.activeModules[moduleType] === sid;
  }
  classroom_type:any
  session_status:any
  excelUserData = []
  getUserDetails(fetchUserList:any,res)
  {
    // console.log("res",res);
    var that =this
    var sess_type = ""
    var userType = ''
    var trainer_type = ''

    if(res.trainer_type == 1){
      trainer_type = 'Facilitator';
    }else if(res.trainer_type == 2){
      trainer_type = 'Lead Trainer';
    }

    if (res.classroom_type == 0) {
      that.classroom_type = 'Online'
    } else if (res.classroom_type == 1) {
      that.classroom_type = 'Offline'
    } else {
      that.classroom_type = 'Online & Offline'
    }

    if(res.status == 0){
        that.session_status = 'Open';
    }else if(res.status == 1 || res.status == 5){
        that.session_status  = 'Completed';
    }else if(res.status == 2){
        that.session_status  = 'Cancelled';
    }else if(res.status == 3){
        that.session_status  = 'In progress';
    }else if(res.status == 4){
        that.session_status  = 'Expired';
    }


    
    // console.log(res)
    // console.log("module",fetchUserList)
    // const date = new Date(res.time_data.session_date * 1000); // Multiply by 1000 to convert seconds to milliseconds
    // var session_date = that.datePipe.transform(date, 'dd-MM-yyyy') || '';
    // const from_time_date = new Date(res.time_data.from_time * 1000); // Multiply by 1000 to convert seconds to milliseconds
    // var from_time = that.datePipe.transform(from_time_date, 'HH:mm:ss') || '';
    // const to_time_date = new Date(res.time_data.to_time * 1000); // Multiply by 1000 to convert seconds to milliseconds
    // var to_time = that.datePipe.transform(to_time_date, 'HH:mm:ss') || '';
    if(fetchUserList.length == 0){
      var data = {}
      data['session_id'] = res.session_id
      data['first_name'] = ''
      data['last_name'] = ''
      data['mobile_no'] =''
      data['email_id'] = ''
      data['UserType'] = ''
      data['RegistrationMethod'] = ''
      data['module_name'] = res.module_name
      data['trainer_name'] = res.trainer_name
      data['trainer_type'] = trainer_type
      if(GlobalConstants.userDetails.cmid == 1 || GlobalConstants.userDetails.cmid == 2935 || GlobalConstants.userDetails.cmid == 2963 ||  GlobalConstants.userDetails.cmid == 2965 ||  GlobalConstants.userDetails.cmid == 2962 || GlobalConstants.userDetails.cmid == 2964 || GlobalConstants.userDetails.cmid == 2974){
        data['trainer_channel'] = res.trainer_channel
        data['trainer_region'] = res.trainer_region
      }
      data['session_name'] = res.session_name
      data['type'] = that.classroom_type
      data['session_key'] = res.session_key    
      data['category'] = res.skill
      data['sub_category'] = res.sub_skill
      data['from_time'] = res.time_data.from_time
      data['to_time'] = res.time_data.to_time
      data['duration'] = res.time_data.duration
      data['sessionDate'] = res.time_data.session_date
      data['publishedDate'] = res.time_data.published_date 
      data['dateOfCompletion'] = ''
      data['pre_score'] = ''
      data['post_score'] =''
      data['completionStatus'] =''
      data['SessionStatus'] = that.session_status
      data['cancel_reason'] = res.cancel_reason
      data['UserStatus'] = ''   
      that.excelUser.push(data);
    }else{
      fetchUserList.forEach(function (item: any,index) {
        const comp_date = new Date(item.complete_date * 1000); // Multiply by 1000 to convert seconds to milliseconds
        var complete_date = that.datePipe.transform(comp_date, 'dd-MM-yyyy') || '';
        if (item.session_type == 0) {
          sess_type = 'Instant'
        } else {
          sess_type = 'Schedule'
        }
        if(item.is_attended == 0){
          that.att_type = 'Not Attended'
        }else{
          that.att_type='Attended'
        }
        var userStatus=""
        if(item.userStatus == 0)
        {
          userStatus = "Active"
        } 
        else if(item.userStatus == 1 || item.userStatus == 3)
        {
          userStatus = "Not Accepted"
        }
        else if(item.userStatus == 7)
        {
          userStatus = "Blocked"
        }
        else if(item.userStatus == 8)
        {
          userStatus = "Not Approved"
        }else{
          userStatus = ""
        }
      
        if(item.registered == 1){
          userType= "Unregistered"
        }else{
          userType= "Registered"
        }

        var reg_method = ''
        if(item.register_method == 1){
          reg_method = 'Session Code'
        }else{
          reg_method = 'By Trainer'
        }

        // item.user_attribute[0]
            var data = {}
            data['session_id'] = res.session_id
            data['first_name'] = item.first_name
            data['last_name'] = item.last_name
            data['mobile_no'] = item.mobile_no
            data['email_id'] = item.email_id
            data['UserType'] = userType
            data['RegistrationMethod'] = reg_method
            data['module_name'] = res.module_name
            // data['Channel'] = res.channel_name
            data['trainer_name'] = res.trainer_name
            data['trainer_type'] = trainer_type
            if(GlobalConstants.userDetails.cmid == 1 || GlobalConstants.userDetails.cmid == 2935 || GlobalConstants.userDetails.cmid == 2963 ||  GlobalConstants.userDetails.cmid == 2965 ||  GlobalConstants.userDetails.cmid == 2962 || GlobalConstants.userDetails.cmid == 2964 || GlobalConstants.userDetails.cmid == 2974){
              data['trainer_channel'] = res.trainer_channel
              data['trainer_region'] = res.trainer_region
            }
            data['session_name'] = res.session_name
            data['type'] = that.classroom_type
            data['session_key'] = res.session_key    
            data['category'] = res.skill
            data['sub_category'] = res.sub_skill
            data['from_time'] = res.time_data.from_time
            data['to_time'] = res.time_data.to_time
            data['duration'] = res.time_data.duration
            data['sessionDate'] = res.time_data.session_date
            data['publishedDate'] = res.time_data.published_date 
            data['dateOfCompletion'] = (item.is_attended==1)?complete_date:''
            data['pre_score'] = item.pre_score
            data['post_score'] = item.post_score
            data['completionStatus'] = (item.is_attended==1)?'Completed':'Not Completed'
            data['SessionStatus'] = that.session_status
            data['cancel_reason'] = res.cancel_reason
            data['UserStatus'] = userStatus    
            if(item.user_attribute.length>0)
            {
              Object.entries(item.user_attribute[0]).forEach(([key, value]) => {
                if(item.registered == 1 && key == 'Region'){
                  data[key+"(User Attributes)"] = item.user_region
                }else{
                  data[key+"(User Attributes)"] = value
                }
                  // data[key+"(User Attributes)"] = value
              })
            }
            // console.log(data)
            that.excelUser.push(data);
      });
    }
    // console.log(that.excelUser)
    return that.excelUserData;
  }
  att_type:any
  toggleActiveModule(module: string,sid:string) {
    if (this.isActiveModule(module,sid)) {
      // If the module is already active, deactivate it
      delete this.activeModules[module];
    } else {
      // Otherwise, activate it
      this.activeModules[module] = sid;
    }
    // console.log(sid)
    this.activeModule = module;
   
    var that = this
    this.classroomService.get_report_type_data(this.activeModule,sid).toPromise().then(res => {
      // console.log(res)
      
      if(this.activeModule == 'module')
      {
        that.excelUser = []
        res.forEach(function (item: any) {
          var data = that.getUserDetails(item.fetchUserList,item)
          // console.log(data)
        })
      }
      else
      {
      // console.log(res.fetchUserList)
        that.excelUser = []
        var fetchUserList = res.fetchUserList
        this.getUserDetails(fetchUserList,res)
      }
      //});
  
      // console.log('excel',this.excelUser)
  
      this.excelService.exportAsExcelFile(this.excelUser, 'session-list');
    })
  }

  // navigate to inside page
  redirect(id:any) {

    if(this.trainer_name=='trainer'){
      this.router.navigate(['/classroom/view_session/', id]);
    }
  }
  toggleDropdown(parentIndex: number, rowIndex: number){
    const isOpen =
    this.openDropdown.parentIndex === parentIndex &&
    this.openDropdown.rowIndex === rowIndex;
    this.openDropdown = isOpen
      ? { parentIndex: null, rowIndex: null }
      : { parentIndex, rowIndex };
  }

  isDropdownOpen(parentIndex: number, rowIndex: number): boolean {
    return (
      this.openDropdown.parentIndex === parentIndex &&
      this.openDropdown.rowIndex === rowIndex
    );
    
  }
  
  // fetchFullSessionList(){
  //   this.fullSessionList = [];
  //   let allSessionPromise = new Promise((resolve, reject) => {
  //     this.classroomService.trainers_session_full_list(this.filterObj).subscribe(res => {
  //       this.fullSessionList = res;
  //       // console.log("full", this.fullSessionList)
  //     })
  //   });
  // }
  //get all last 3 month data
  // lastThreeMonthSessionList(){
  //   this.spinner.show();
  //   this.lastMonthSessionList = [];
  //   let allSessionPromise = new Promise((resolve, reject) => {
  //     this.classroomService.last_three_month_session_list(this.filterObj).subscribe(res => {
  //       this.lastMonthSessionList = res;
  //       this.spinner.hide();
  //       this.cdr.detectChanges();
  //       // console.log("full", this.lastMonthSessionList)
  //     })
  //   });
  // }

  //last 3 months data with users
  downloadAllSessionData(){

    this.spinner.show();
    this.lastMonthSessionList = [];
    var that =this
    var trainer_type = ''
    var classroom_type = ''
    var session_status = ''
    that.all_user = []
    let allSessionPromise = new Promise((resolve, reject) => {
      this.classroomService.last_three_month_session_list(this.filterObj).subscribe(res => {
        this.lastMonthSessionList = res;     
        if(this.lastMonthSessionList[0] == false){
          alertify.error("Date range must be lesser than or equal to 60 days.");
          this.spinner.hide();
        }else if(this.lastMonthSessionList.length == 0){
          alertify.error("There is no data to download");
          this.spinner.hide();
        }else{
          this.lastMonthSessionList.forEach(function (item: any) {
          
            if(item.trainer_type == 1){
              trainer_type = 'Facilitator';
            }else if(item.trainer_type == 2){
              trainer_type = 'Lead Trainer';
            }

            if(item.status == 0){
              session_status = 'Open';
            }else if(item.status == 1 || item.status == 5){
                session_status  = 'Completed';
            }else if(item.status == 2){
                session_status  = 'Cancelled';
            }else if(item.status == 3){
                session_status  = 'In progress';
            }else if(item.status == 4){
                session_status  = 'Expired';
            }
        
            if (item.classroom_type == 0) {
              that.classroom_type = 'Online'
            } else if (item.classroom_type == 1) {
              that.classroom_type = 'Offline'
            } else {
              that.classroom_type = 'Online & Offline'
            }
            // if (item.session_type == 0) {
            //   sess_type = 'Instant'
            // } else {
            //   sess_type = 'Schedule'
            // }
            if(item.fetch_users.length == 0){
              var data = {}
              data['session_id'] = item.sid
              data['first_name'] = ''
              data['last_name'] = ''
              data['mobile_no'] =''
              data['email_id'] = ''
              data['userType'] = ''
              data['registration_method'] = ''
              data['module_name'] = item.module_name
              data['trainer_name'] = item.trainer_name
              data['trainer_email_id'] = item.trainer_email
              data['trainer_mobile_no'] = item.trainer_mobile 
              data['trainer_type'] = trainer_type
              if(GlobalConstants.userDetails.cmid == 1 || GlobalConstants.userDetails.cmid == 2935 || GlobalConstants.userDetails.cmid == 2963 ||  GlobalConstants.userDetails.cmid == 2965 ||  GlobalConstants.userDetails.cmid == 2962 || GlobalConstants.userDetails.cmid == 2964 || GlobalConstants.userDetails.cmid == 2974){
                data['trainer_channel'] = item.trainer_channel
                data['trainer_region'] = item.trainer_region
              }
              data['session_name'] = item.session_name
              data['classroom_type'] = that.classroom_type
              data['session_key'] = item.session_key
              data['category'] = item.category
              data['sub_category'] = item.sub_category
              data['from_time'] = item.time.from_time
              data['to_time'] = item.time.to_time
              data['duration'] = item.time.duration
              data['sessionDate'] = item.time.session_date
              data['publishedDate'] = item.time.published_date
              data['dateOfCompletion'] = ''
              data['pre_score'] = ''
              data['post_score'] =''
              data['attendance_status'] = ''
              data['session_status'] = session_status 
              data['cancel_reason'] = item.cancel_reason
              data['user_status'] =  ''         
              that.all_user.push(data);
            }else{
              item.fetch_users.forEach(function (user:any){
                const comp_date = new Date(user.complete_date * 1000); // Multiply by 1000 to convert seconds to milliseconds
                var complete_date = that.datePipe.transform(comp_date, 'dd-MM-yyyy') || '';
      
                var userType = ''
                if(user.registered == 1){
                  userType= "Unregistered"
                }else{
                  userType= "Registered"
                }
                var user_status = ''
                if(user.userStatus == 0)
                {
                    user_status = "Active"
                } 
                else if(user.userStatus == 1 || user.userStatus == 3)
                {
                  user_status = "Not Accepted"
                }
                else if(user.userStatus == 7)
                {
                  user_status = "Blocked"
                }
                else if(user.userStatus == 8)
                {
                  user_status = "Not Approved"
                }else{
                  user_status = " "
                }
      
                var reg_method = ''
                if(user.register_method == 1){
                  var reg_method = 'Session Code'
                }else{
                  var reg_method = 'By Trainer'
                }
      
                var data = {}
                data['session_id'] = item.sid
                data['first_name'] = user.first_name
                data['last_name'] = user.last_name
                data['mobile_no'] = user.mobile_no
                data['email_id'] = user.email_id
                data['userType'] = userType;
                data['registration_method'] = reg_method
                data['module_name'] = item.module_name
                data['trainer_name'] = item.trainer_name
                data['trainer_email_id'] = item.trainer_email
                data['trainer_mobile_no'] = item.trainer_mobile 
                data['trainer_type'] = trainer_type
                if(GlobalConstants.userDetails.cmid == 1 || GlobalConstants.userDetails.cmid == 2935 || GlobalConstants.userDetails.cmid== 2963 ||  GlobalConstants.userDetails.cmid == 2965 ||  GlobalConstants.userDetails.cmid == 2962 || GlobalConstants.userDetails.cmid == 2964 || GlobalConstants.userDetails.cmid == 2974){
                  data['trainer_channel'] = item.trainer_channel
                  data['trainer_region'] = item.trainer_region
                }
                data['session_name'] = item.session_name
                data['classroom_type'] = that.classroom_type
                // data['session_type'] = sess_type
                data['session_key'] = item.session_key
                data['category'] = item.category
                data['sub_category'] = item.sub_category
                data['from_time'] = item.time.from_time
                data['to_time'] = item.time.to_time
                data['duration'] = item.time.duration
                data['sessionDate'] = item.time.session_date
                data['publishedDate'] = item.time.published_date
                data['dateOfCompletion'] = (user.is_attended==1)? complete_date: ''
                data['pre_score'] = user.pre_score
                data['post_score'] = user.post_score
                data['attendance_status'] = (user.is_attended) ? 'Attended' : 'Not Attended' 
                data['session_status'] = session_status 
                data['cancel_reason'] = item.cancel_reason
                data['user_status'] =  user_status         
                if(user.user_attribute.length>0)
                  {
                    Object.entries(user.user_attribute[0]).forEach(([key, value]) => {
                      if(user.registered == 1 && key == 'Region'){
                        data[key+"(User Attributes)"] = user.user_region
                      }else{
                        data[key+"(User Attributes)"] = value
                      }
                        // data[key+"(User Attributes)"] = value
                    })
                  }
                  that.all_user.push(data);
              }); 
            }
          
          });
          this.excelService.exportAsExcelFile(this.all_user, 'overall-session-report');
          this.spinner.hide();
          this.cdr.detectChanges();
        }
      });
    });
   
    // var sess_type = ''
    // this.isFilterApplied = false
   
  
  }


  //clone rtm classroom session
  CloneRtmSession(sid:any) {
    if(sid!=0){

      let getPromise = new Promise((resolve, reject) => {
      this.classroomService.clone_rtm_classroom_session(sid).toPromise().then(res => {
        //console.log(res)
        if (res == 'success') {
          alertify.success('Session cloned susccessfully');
          // this.fetchFullSessionList();
          this.fetchSessionList();
          // this.lastThreeMonthSessionList();
          this.AllrtmTopicList();
          // Close the dropdown after action
          this.openDropdown = { parentIndex: null, rowIndex: null };
          this.cdr.detectChanges();
          //this.attendanceSubmitted = true;
        } else {
          alertify.error('Sorry, Something went wrong');
        }
      })
    })
    }
  }

}
